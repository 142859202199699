




































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { MobilePaymentInfoProperties } from "./MobilePaymentInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { MobilePaymentReceiptDialogProperties } from "./MobilePaymentReceiptDialogView.vue";
import DateUtil from '@/utilities/DateUtil';

@Component({})
export default class MobilePaymentSummaryView extends Vue {
    @Prop() private properties: MobilePaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private paymentReceiptDialog: MobilePaymentReceiptDialogProperties;

    public get deliveryOptions() {
        return [
            {
                text: this.$t("text.standard-delivery"),
                value: "Standard-Delivery",
            },
            {
                text: this.$t("text.on-hold-delivery"),
                value: "On-Hold-Delivery",
            },
            {
                text: this.$t("text.store-pickup"),
                value: "Store-Pickup",
            },
        ];
    }

    public get payment() {
        return this.properties.payment;
    }

    public get isDeposit() {
        return this.payment.type === "Deposit";
    }

    public get isCheckout() {
        return this.payment.type === "Checkout";
    }

    public get isMixed() {
        return this.payment.paymentMethod === "Mixed";
    }

    public get isCash() {
        return this.payment.paymentMethod === "Cash";
    }

    public get isReceipt() {
        return this.payment.paymentMethod === "Receipt";
    }

    public get isWallet() {
        return this.payment.paymentMethod === "Wallet";
    }

    public get isProcessing() {
        return this.payment.status === "Processing";
    }

    public get status() {
        const v = this.payment.status;
        switch (v) {
            case "Processing": return this.$t("text.processing");
            case "Approved": return this.$t("text.approved");
            case "Cancelled": return this.$t("text.cancelled");
            case "Refunded": return this.$t("text.refunded");
            case "Rejected": return this.$t("text.rejected");
            case "Delivered": return this.$t("text.delivered");
            case "Completed": return this.$t("text.completed");
        }
        return v;
    }

    public get type() {
        const v = this.payment.type;
        switch (v) {
            case "Deposit": return this.$t("text.deposit");
            case "Checkout": return this.$t("text.checkout");
        }
        return v;
    }

    public get paymentMethod() {
        const v = this.payment.paymentMethod;
        switch (v) {
            case "Cash": return this.$t("text.cash");
            case "Wallet": return this.$t("text.wallet");
            case "Receipt": return this.$t("text.receipt");
            case "Mixed": return this.$t("text.mixed");
        }
        return v;
    }

    public get paymentDate() {
        const v = this.payment.paymentDate;
        const f = this.properties.dateTimeFormat;
        return v ? DateUtil.format(new Date(v), f) : "";
    }

    public get shipping() {
        return this.properties.payment.shipping;
    }

    public get currency() {
        return this.properties.currency;
    }

    public get cashPayment() {
        return this.payment.cashPayment ?? 0;
    }

    public get walletPayment() {
        return this.payment.walletPayment ?? 0;
    }

    public get receiptPayment() {
        return this.payment.receiptPayment ?? 0;
    }

    public get totalPayment() {
        return this.cashPayment + this.walletPayment + this.receiptPayment;
    }

    public get subtotal() {
        return this.payment.subtotal ?? 0;
    }

    public get shippingFee() {
        return this.payment.shippingFee ?? 0;
    }

    public get discountAmount() {
        return this.payment.discountAmount ?? 0;
    }

    public get totalAmount() {
        return this.payment.totalAmount ?? 0;
    }

    public isDelivery(deliveryMethod: string) {
        return this.payment.deliveryMethod === deliveryMethod;
    }

    public async cancel() {
        await this.properties.events.fire("cancel");
    }

    public viewReceipt() {
        this.paymentReceiptDialog.visible = true;
    }
}
